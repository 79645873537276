// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover4-content {
  margin-top: 50px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.cover4-content .cover4-head {
  padding: 0px 13px;
}
.cover4-content .cover4-head h1 {
  color: #0C2B76;
}
.cover4-content .cover4-head .cover4-job {
  color: #F49342;
  font-size: 1.2em;
  margin: 0px;
}
.cover4-content .cover4-head .cover4-contactInfo {
  display: flex;
}
.cover4-content .cover4-head .cover4-contactInfo .cover4-contactInfo-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.cover4-content .cover4-head .cover4-contactInfo .cover4-contactInfo-item .cover4-icon {
  margin-right: 10px;
}
.cover4-content .cover4-body {
  display: flex;
  padding: 0px 13px;
  box-sizing: border-box;
  flex-direction: column;
}
.cover4-content .cover4-body .cover4-body-left {
  width: 70%;
}
.cover4-content .cover4-body .cover4-body-right {
  padding-right: 20px;
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/cv-templates/cover4/Cover4.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EAEA,iCAAA;AAAJ;AAGI;EACI,iBAAA;AADR;AAEQ;EACI,cAAA;AAAZ;AAEQ;EACI,cAAA;EACA,gBAAA;EACA,WAAA;AAAZ;AAEQ;EACI,aAAA;AAAZ;AACY;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AAChB;AAAgB;EACI,kBAAA;AAEpB;AAKI;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,sBAAA;AAHR;AAIQ;EACI,UAAA;AAFZ;AAIQ;EAEI,mBAAA;EACA,UAAA;AAHZ","sourcesContent":[".cover4-content{\r\n    margin-top: 50px;\r\n    width: 90%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-size: 14px;\r\n    // reset font to default\r\n    font-family: 'Roboto', sans-serif;\r\n    \r\n    \r\n    .cover4-head{\r\n        padding: 0px 13px;\r\n        h1{\r\n            color: #0C2B76;\r\n        }\r\n        .cover4-job{\r\n            color: #F49342;\r\n            font-size: 1.2em;\r\n            margin: 0px;\r\n        }\r\n        .cover4-contactInfo{\r\n            display: flex;\r\n            .cover4-contactInfo-item{\r\n                display: flex;\r\n                align-items: center;\r\n                margin-right: 20px;\r\n                .cover4-icon{\r\n                    margin-right: 10px;\r\n                }\r\n            }\r\n        }\r\n\r\n\r\n    }\r\n    .cover4-body{\r\n        display:flex;\r\n        padding: 0px 13px;\r\n        box-sizing: border-box;\r\n        flex-direction: column;\r\n        .cover4-body-left{\r\n            width: 70%;\r\n        }\r\n        .cover4-body-right{\r\n \r\n            padding-right: 20px;\r\n            width: 30%;\r\n        }\r\n\r\n\r\n        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
