// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover3-content {
  margin-top: 50px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.cover3-content .cover3-head {
  padding: 0px 13px;
}
.cover3-content .cover3-body {
  display: flex;
  padding: 0px 13px;
  box-sizing: border-box;
}
.cover3-content .cover3-body .cover3-body-left {
  width: 70%;
  padding-right: 20px;
}
.cover3-content .cover3-body .cover3-body-right {
  padding-right: 20px;
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./src/cv-templates/cover3/Cover3.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EAEA,iCAAA;AAAJ;AAGI;EACI,iBAAA;AADR;AAII;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;AAFR;AAGQ;EACI,UAAA;EACA,mBAAA;AADZ;AAGQ;EAEI,mBAAA;EACA,UAAA;AAFZ","sourcesContent":[".cover3-content{\r\n    margin-top: 50px;\r\n    width: 90%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-size: 14px;\r\n    // reset font to default\r\n    font-family: 'Roboto', sans-serif;\r\n    \r\n    \r\n    .cover3-head{\r\n        padding: 0px 13px;\r\n\r\n    }\r\n    .cover3-body{\r\n        display:flex;\r\n        padding: 0px 13px;\r\n        box-sizing: border-box;\r\n        .cover3-body-left{\r\n            width: 70%;\r\n            padding-right:20px\r\n        }\r\n        .cover3-body-right{\r\n \r\n            padding-right: 20px;\r\n            width: 30%;\r\n        }\r\n\r\n\r\n        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
