// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading .spinner {
  width: 300px;
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/spinner.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,aAAA;AAAR","sourcesContent":[".loading{\r\n    .spinner{\r\n        width: 300px;\r\n        height: 300px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
