// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover1-content {
  margin-top: 50px;
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
.cover1-content .cover1-separatorLine {
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 10px 0;
}
.cover1-content .cover1-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 10px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/cv-templates/cover1/Cover1.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EAEA,iCAAA;AAAJ;AAII;EACI,WAAA;EACA,WAAA;EACA,sBAAA;EACA,cAAA;AAFR;AAKI;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;AAHR","sourcesContent":[".cover1-content{\r\n    margin-top: 50px;\r\n    width: 90%;\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-size: 14px;\r\n    // reset font to default\r\n    font-family: 'Roboto', sans-serif;\r\n    \r\n    \r\n\r\n    .cover1-separatorLine{\r\n        width: 100%;\r\n        height: 1px;\r\n        background-color: #000;\r\n        margin: 10px 0;\r\n    }\r\n\r\n    .cover1-head{\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: center;\r\n        line-height: 10px;\r\n        align-items: center;\r\n\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
